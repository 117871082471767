<template>
  <div class="footer">
    <div class="col-footer">
      <el-menu class="el-menu-footer">
        <el-menu-item class="simLogo" index="1">
          <img
            src="@/assets/simpatra_logo_white.png"
            class="logo"
          >
        </el-menu-item>
        <el-menu-item index="1">
          <a href="">About Us</a>
        </el-menu-item>
        <el-menu-item index="2">
          <a href="/contact-us">Contact</a>
        </el-menu-item>
        <el-menu-item index="3">
          <a href="#">Help Center</a>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="col-footer">
      <el-menu class="el-menu-footer">
        <el-menu-item index="1">
          <a href="">Marketplace</a>
        </el-menu-item>
        <el-menu-item index="2">
          <a href="#">Pharmacy</a>
        </el-menu-item>
        <el-menu-item index="3">
          <a href="#">Medical Supplies</a>
        </el-menu-item>
        <el-menu-item index="1">
          <a href="">Pharmaceuticals</a>
        </el-menu-item>
        <el-menu-item index="2">
          <a href="#">Nutraceuticals</a>
        </el-menu-item>
        <el-menu-item index="3">
          <a href="#">Medical Equipment</a>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="col-footer">
      <el-menu class="el-menu-footer">
        <el-menu-item index="1">
          <a href="">Med center</a>
        </el-menu-item>
        <el-menu-item index="2">
          <a href="#">Wellness</a>
        </el-menu-item>
        <el-menu-item index="3">
          <a href="#">Food Sensitivity tests</a>
        </el-menu-item>
        <el-menu-item index="3">
          <a href="#">Allergy tests</a>
        </el-menu-item>
        <el-menu-item index="3">
          <a href="#">Women's Health tests</a>
        </el-menu-item>
        <el-menu-item index="3">
          <a href="#">Men's Health Tests</a>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="col-footer">
      <el-menu class="el-menu-footer">
        <el-menu-item index="1">
          <a href="">Intellipel</a>
        </el-menu-item>
        <el-menu-item index="2">
          <a href="#">Training</a>
        </el-menu-item>
        <el-menu-item index="3">
          <a href="#">dose calculation BHRT</a>
        </el-menu-item>
        <el-menu-item index="4">
          <a href="#">Product supply ordering</a>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="col-footer">
      <el-menu class="el-menu-footer">
        <el-menu-item index="1">
          <a href="">Practice builder</a>
        </el-menu-item>
        <el-menu-item index="2">
          <a href="#">Create wesite</a>
        </el-menu-item>
        <el-menu-item index="3">
          <a href="#">Customize your wesite </a>
        </el-menu-item>
        <el-menu-item index="4">
          <a href="#">attracting customers</a>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="copywright">
      © 2021 SIMPATRA, INC.ALL RIGHTS RESERVED
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePageFooter',
};
</script>

<style scoped>
.footer{
    background-color: #394456;
    float: left;
    width: 100%;
    padding: 60px;
    padding-bottom: 20px;
}
.col-footer{
    width: 20%;
    border: 0;
    margin: 0;
    padding: 0;
    float: left;
}
.col-footer .el-menu{
    background-color: transparent;
    border: none;
}
.logo{
    width: max-content;
}
.el-menu .el-menu-item:first-child{
    font-weight: bold;
}
.el-menu .el-menu-item:first-child a{
    font-weight: bold;
}
.simLogo{
  padding-left: unset !important;
}
.el-menu-item{
    height: 34px;
    line-height: 34px;
    align-items: center;
}
.el-menu-item a{
    color: #FFF;
    text-transform: uppercase;
    font-size: 14px;
    font-style: normal;
    font-weight: normal;
}
.el-menu-item:focus, .el-menu-item:hover{
    background: transparent;
}
.copywright {
    display: flex;
    width: 100%;
    color: #FFF;
    justify-content: center;
    padding-top: 76px;
}
/* Mobile screen css */
@media only screen and (max-width: 993px) {
  .footer {
    padding: 35px;
  }
  .col-footer {
    width: 100%;
    margin: 0 -20px;
    margin-bottom: 45px;
  }
  .el-menu-item {
    padding: 0;
  }
  .copywright {
    padding-top: 0;
  }
}
</style>
