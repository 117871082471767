<template lang="html">
  <div id="">
    <div class="auto-shipment">
      Auto Shipment
    </div>
  </div>
</template>
<script>
export default {
  name: 'MarketplaceAutoShipment',
  data() {
    return {

    };
  },
};
</script>
<style scoped>
/* .cart {
    width: 20%;
} */
</style>
