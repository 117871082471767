<template lang="html">
  <LayoutCentered class="container">
    <div class="my-stock-room">
      <div class="route">
        <router-view />
      </div>
      <br style="clear: left;">
    </div>
  </LayoutCentered>
</template>
<script>
import LayoutCentered from '@/components/common/LayoutCentered.vue';

export default {
  name: 'MarketplaceMyStockroomApps',
  components: {
    LayoutCentered,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>
<style scoped>
.my-stock-room {
  margin-top: 50px;
}
</style>
