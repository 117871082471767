<template lang="html">
  <div id="location-restock">
    <div>
      <ReStock
        :key="product.productId"
        :product="product"
        :location-id="locationId"
        :added-item="addedItem"
        :product-id="product.productId"
        class="fulfillment-list-item"
        :get-selected-current-location-id="getSelectedCurrentLocationId"
        :get-cart-items="getCartItems"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import ReStock from '@/components/marketplace/ReStock.vue';

export default {
  name: 'LocationReStock',
  components: {
    ReStock,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    locationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      getSelectedCurrentLocationId: 'getSelectedCurrentLocationId',
      getCartItems: 'getCartItems',
    }),
    addedItem: {
      get() {
        const isItem = this.getCartItems.find((item) => {
          if (item.productId === this.product.productId) {
            return true;
          }
          return false;
        });
        if (!isItem) {
          return false;
        }
        return true;
      },
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions({
    }),
  },
};
</script>
<style scoped>
</style>
