<template lang="html">
  <div id="re-stock">
    <MarketplaceProductDetailsDrawer
      v-if="activeDrawer"
      :drawer-product-id="drawerProductId"
      :product="product"
      @closeProductDrawer="closeProductDrawer"
    />
    <BaseListCard
      class="re-stock-item"
    >
      <template v-slot:left>
        <div
          :class="{ 'edit-card' :(selectedProductId === productId && editable) || addedItem}"
          class="left"
          @click="productDetailsDrawer(productId)"
        >
          <div class="product-info-container">
            <div
              v-if="product.imageURL !== ''"
              class="image-wrapper"
            >
              <img
                :src="product.imageURL"
                class="image"
              >
            </div>
            <div
              v-else
              class="image-wrapper"
            >
              <img
                :src="categoryImage('Medical Supplies')"
                :alt="product.categoryName"
                class="image"
              >
            </div>
            <div class="order-details">
              <div class="product-name">
                <span class="name">{{ product.productName }}</span>
                <span>-</span>
                <span class="price">
                  ${{ product.price.toFixed(2) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:right>
        <div class="right">
          <div
            :class="{ 'edit-card' :(selectedProductId === productId && editable) || addedItem}"
            class="subtotal-wrapper"
          >
            <span class="subtotal">
              ${{ (qty * product.price).toFixed(2) }}
            </span>
          </div>
          <i
            v-if="(!editable && !addedItem)"
            class="qty-update item-add el-icon-circle-plus"
            @click="reOrder(product, productId)"
          />
          <div
            v-if="(selectedProductId === productId && editable) || addedItem"
            class="edit"
            @click="editProduct(productId)"
          >
            <i class="edit-icon el-icon-edit" />
          </div>
        </div>
      </template>
    </BaseListCard>
  </div>
</template>
<script>
import { Message } from 'element-ui';
import { mapActions, mapGetters } from 'vuex';
import BaseListCard from '@/components/common/BaseListCard.vue';
import MarketplaceProductDetailsDrawer from '@/components/marketplace/common/MarketplaceProductDetailsDrawer.vue';
import { findCategoryImage } from '@/utils/imagePlaceholder';

export default {
  name: 'ReStock',
  components: {
    BaseListCard,
    MarketplaceProductDetailsDrawer,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    locationId: {
      type: String,
      required: true,
    },
    addedItem: {
      type: Boolean,
      required: true,
    },
    productId: {
      type: String,
      required: true,
    },
    getSelectedCurrentLocationId: {
      type: String,
      required: true,
    },
    getCartItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      itemAdded: false,
      drawerProductId: '',
      activeDrawer: false,
      qty: 1,
      selectedProductId: 0,
      editable: false,
    };
  },
  computed: {
    ...mapGetters({
      getProduct: 'marketplaceProduct/getProduct',
    }),
  },
  async mounted() {
    this.qty = this.product.qty;
    this.$root.$on('deleteProduct', (result) => {
      this.editable = result;
    });
  },
  methods: {
    ...mapActions({
      addItemToCart: 'marketplaceProduct/addItemToCart',
      removeItemFromCart: 'removeItemFromCart',
      fetchProductById: 'marketplaceProduct/fetchProductById',
      setEmptyCart: 'setEmptyCart',
    }),
    categoryImage(c) {
      return findCategoryImage(c);
    },
    productDetailsDrawer(productId) {
      this.activeDrawer = !this.activeDrawer;
      this.drawerProductId = productId;
      this.$root.$emit('closeCartDrawer', false);
    },
    closeProductDrawer() {
      this.activeDrawer = !this.activeDrawer;
    },
    async editProduct(id) {
      this.itemAdded = true;
      this.editable = false;
      this.selectedProductId = id;
      this.getCartItems.forEach(async (item) => {
        if (item.productId === id) {
          await this.removeItemFromCart({
            locationId: this.getSelectedCurrentLocationId,
            productId: item.id,
          });
        }
      });
    },
    async reOrder(product, id) {
      await this.fetchProductById(product.productId);
      if (this.qty < this.getProduct.minQtyForPurchase) {
        Message({
          type: 'warning',
          showClose: true,
          message: `The product ${this.product.productName} has a minimum order quantity of ${this.getProduct.minQtyForPurchase}`,
        });
      } else if (!this.getProduct.inStock) {
        Message({
          type: 'warning',
          showClose: true,
          message: 'This product has out of stock!',
        });
      } else if (this.getProduct.productPricingId !== product.productPricingId) {
        this.itemAdded = true;
        this.selectedProductId = id;
        this.editable = true;
        if (this.getProduct.price > product.price) {
          Message({
            message: `Pricing has changed on this product since your last order, the price has increased by $${this.getProduct.price - product.price}. 
            The current price is $${this.getProduct.price}`,
          });
        }

        if (this.getProduct.price < product.price) {
          Message({
            message: `Pricing has changed on this product since your last order, the price has decreased by $${product.price - this.getProduct.price}.
            The current price is $${this.getProduct.price}`,
          });
        }
        const data = {
          productId: product.productId,
          productPricingId: this.getProduct.productPricingId,
          qty: this.qty,
          locationId: this.locationId,
        };
        await this.addItemToCart(data).then(() => {
          this.setEmptyCart(false);
          Message({
            type: 'success',
            showClose: true,
            message: `The product ${this.product.productName} successfully added into the cart.`,
          });
        }).catch((e) => {
          Message({
            type: 'error',
            showClose: true,
            message: e,
          });
        });
      } else {
        this.itemAdded = true;
        this.selectedProductId = id;
        this.editable = true;
        const data = {
          productId: product.productId,
          productPricingId: product.productPricingId,
          qty: this.qty,
          locationId: this.locationId,
        };
        await this.addItemToCart(data).then(() => {
          this.setEmptyCart(false);
          Message({
            type: 'success',
            showClose: true,
            message: `The product ${this.product.productName} successfully added into the cart.`,
          });
        }).catch((e) => {
          Message({
            type: 'error',
            showClose: true,
            message: e,
          });
        });
      }
    },
  },
};
</script>
<style scoped>
.out-stock {
    width: 125px;
    text-align: right;
  }
.column {
      font-size: 14px;
    }
.edit-icon {
  padding: 8px;
}
.re-stock-item:hover{
 box-shadow: 2px 2px 0px #e0dbdb;
}
.edit-card {
  opacity: 0.2;
}
.left {
  width: 810px;
}
.edit {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #e0dbdb;
    opacity: 2;
}
.qty-update {
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f05a28;
  opacity: 5;
  transition: all .2s ease;
  margin: 0px 5px;
}
.item-add {
  font-size: 32px !important;
  margin-right: 0px;
}
.subtotal-wrapper {
  min-width: 100px;
  display: flex;
  justify-content: flex-end;
}
.subtotal {
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 8px;
}
.price, .qty, .subtotal {
  font-size: 16px;
}
.order-date {
    font-size: 14px;
}
.price {
    font-weight: 600;
}
.right {
  display: flex;
  align-items: center;
}
.order-id {
    font-size: 16px;
    font-weight: 600;
    padding: 5px;
 }
 .image {
  object-fit: cover;
  max-height: 100%;
  max-width: 100%;
}
.image-wrapper {
  width: 65px;
  height: 60px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.no-image {
  width: 65px;
  padding-top: 15px;
}
 .product-name {
    padding: 5px;
    font-size: 16px;
    font-weight: 600;
    font-size: 14px;
 }
 .product-info-container {
     display: flex;
 }
 .order-details {
     margin-left: 20px;
     font-size: 14px;
 }

</style>
