import { render, staticRenderFns } from "./MarketplaceAutoShipment.vue?vue&type=template&id=adf5944c&scoped=true&lang=html"
import script from "./MarketplaceAutoShipment.vue?vue&type=script&lang=js"
export * from "./MarketplaceAutoShipment.vue?vue&type=script&lang=js"
import style0 from "./MarketplaceAutoShipment.vue?vue&type=style&index=0&id=adf5944c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "adf5944c",
  null
  
)

export default component.exports