<template>
  <div>
    <el-tooltip class="tooltip" effect="dark" :content="formattedDateTime(order.createdAt)" placement="right">
      <span>Order placed {{ formattedTimeSince(order.createdAt) }}</span>
    </el-tooltip>
    <div class="order-card">
      <BaseListCard
        class="fulfillment-list-wrapper"
        :class="{
          'is-expanded': showOrderDetails,
        }"
      >
        <template v-if="!showOrderDetails" v-slot:left>
          <div class="left-content">
            <div class="product-info-container">
              <div v-if="order.fulfillments[0].items[0].imageURL" class="image-wrapper">
                <img :src="order.fulfillments[0].items[0].imageURL" class="image" />
              </div>
              <div v-else class="image-wrapper">
                <img :src="categoryImage('Medical Supplies')" class="image" />
              </div>
              <div class="order-details">
                <div class="content-details order-id">
                  <label>Order No</label>
                  <span>{{ order.purchaseId }}</span>
                </div>
                <a-divider type="vertical" />
                <div class="content-details order-date">
                  <label>Paid At</label>
                  {{ order.paidAt ? formattDate(order.paidAt) : "Not paid." }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="!showOrderDetails" v-slot:right>
          <div class="right-content">
            <div class="order-total">
              <label>Total</label>
              <span> ${{ order.authAmount.toFixed(2) }} </span>
            </div>
            <a-divider type="vertical" />
            <div class="expand-btn" @click="displayOrderDetails()">
              <font-awesome-icon class="icon" icon="eye" />
            </div>
          </div>
        </template>
        <template v-else v-slot:bottom>
          <div class="bottom">
            <div class="order-details-header col-wrapper">
              <div class="col-row-wrapper col-row-1">
                <div class="row-info">
                  <p class="row-info-label">ORDER #</p>
                  <p class="value">
                    {{ order.purchaseId }}
                  </p>
                </div>
                <div class="row-info">
                  <p class="row-info-label">ORDER PLACED</p>
                  <p class="value">
                    {{ orderPlacedDate }}
                  </p>
                </div>
                <div class="row-info">
                  <p class="row-info-label">TOTAL</p>
                  <p class="value">${{ order.authAmount.toFixed(2) }}</p>
                </div>
                <div class="row-info">
                  <p class="row-info-label">SHIP TO</p>
                  <p class="value">
                    {{ userName }}
                  </p>
                </div>
              </div>
              <div class="col-row-wrapper col-row-2">
                <div class="row-info">
                  <p class="row-info-label">ORDER STATUS</p>
                  <DynamicStatus :status="order.status" status-type="order-history" />
                </div>
                <!-- <div class="row-info">
                  <p class="row-info-label">ORDER #{{ order.purchaseId }}</p>
                  <div class="row-info--right-info-row">
                    <div class="details">Order Details</div>
                    <a-divider type="vertical" />
                    <div class="receipt">
                      <PurchaseOrderReceipt> Receipts </PurchaseOrderReceipt>
                    </div>
                  </div>
                </div> -->
                <div class="row-info">
                  <div class="expand-btn" @click="displayOrderDetails()">
                    <font-awesome-icon class="icon" icon="eye-slash" />
                  </div>
                </div>
              </div>
            </div>
            <LocationOrderFulfillments
              v-if="showOrderDetails"
              :order="order"
              :order-number="order.purchaseId"
              class="location-order"
            />
          </div>
          <div />
        </template>
      </BaseListCard>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { findCategoryImage } from "@/utils/imagePlaceholder";
// import BaseLineItem from "@/components/common/BaseLineItem.vue";
import { monthDayYearTime, timeSince } from "@/plugins/moment";
import BaseListCard from "@/components/common/BaseListCard.vue";
import LocationOrderFulfillments from "@/components/dashboard/LocationOrderFulfillments.vue";
// import PurchaseOrderReceipt from "@/components/marketplace/common/PurchaseOrderReceipt.vue";
import DynamicStatus from "@/components/common/AntDesign/DynamicStatus.vue";

export default {
  name: "LocationOrder",
  components: {
    BaseListCard,
    LocationOrderFulfillments,
    // BaseLineItem,
    // PurchaseOrderReceipt,
    DynamicStatus,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showOrderDetails: false,
      selectedIndex: 0,
      orderNumber: 0,
      productId: "",
    };
  },
  computed: {
    ...mapGetters(["getCurrentUser", "getPurchaseReceipts"]),
    orderPlacedDate() {
      if (this.order.createdAt) {
        return moment(String(this.order.createdAt)).format("MMMM DD, YYYY");
      }
      return "";
    },
    userName() {
      return `${this.getCurrentUser.firstName} ${this.getCurrentUser.lastName}`;
    },
    isFulfillmentExpanded() {
      return this.showOrderDetails;
    },
  },
  created() {},
  methods: {
    categoryImage(c) {
      return findCategoryImage(c);
    },
    statusType(status) {
      if (status === "Processing") {
        return "warning";
      }
      if (status === "Shipped") {
        return "success";
      }
      return "danger";
    },
    formattedDateTime(date) {
      return monthDayYearTime(date);
    },
    formattedDateTimeformattedDateTime(date) {
      return monthDayYearTime(date);
    },
    formattedTimeSince(date) {
      return timeSince(date);
    },
    displayOrderDetails() {
      this.showOrderDetails = !this.showOrderDetails;
    },
    formattDate(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
      return "";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./my-stockroom/order-history/styles/LocationOrder.scss";
</style>
