<template lang="html">
  <div id="re-order">
    <div
      class="re-order"
    >
      <div class="header">
        <div class="title">
          <h3>Restock</h3>
        </div>
        <div
          v-if="getLocationOrders.length > 0"
          class="search-bar"
        >
          <el-input
            v-model="search"
            placeholder="Search Item"
            prefix-icon="el-icon-search"
          />
        </div>
        <div
          v-if="getLocationOrders.length > 0"
          class="filter"
        >
          <label>Filter By: Most Recent</label>
        </div>
      </div>
      <div v-if="getLocationOrders.length > 0">
        <LocationReStock
          v-for="(product, index) in filteredList"
          :key="product.id"
          :index="index"
          :product="product"
          :location-id="locationId"
          :bottom-divider="needsBottomDivider(index)"
          class="location-order"
        />
      </div>
      <BaseNoData
        v-else
        class="empty"
      >
        Order not found
      </BaseNoData>
      <div
        v-if="getNumberOfProductsPages > 1"
        class="pagination-wrapper"
      >
        <BasePagination
          :pages="getNumberOfProductsPages"
          :current-page="getPage"
          @previous="prevPage"
          @next="nextPage"
          @page-clicked="commitPage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import LocationReStock from '@/components/marketplace/LocationReStock.vue';
import BaseNoData from '@/components/common/BaseNoData.vue';
import BasePagination from '@/components/common/BasePagination.vue';

export default {
  name: 'MarketplaceReOrder',
  components: {
    LocationReStock,
    BaseNoData,
    BasePagination,
  },
  props: {
    locationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      search: '',
      productList: [],
    };
  },
  computed: {
    ...mapGetters({
      getLocationOrders: 'getLocationOrders',
      getPageProductsResults: 'getPageProductsResults',
      getNumberOfProductsPages: 'getNumberOfProductsPages',
      getPage: 'getPage',
      getLocationProductsList: 'getLocationProductsList',
    }),
    filteredList() {
      return this.getPageProductsResults.filter((product) => {
        return product.productName.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  async created() {
    await this.fetchLocationProducts(this.locationId).catch(() => {});
  },
  methods: {
    ...mapActions({
      fetchLocationProducts: 'fetchLocationProducts',
      fetchLocationPurchases: 'fetchLocationPurchases',
      setProductList: 'setProductList',
      nextPage: 'nextPage',
      prevPage: 'prevPage',
      commitPage: 'commitPage',
    }),
    async iterateOrder() {
      this.getLocationOrders.forEach(async (data) => {
        data.fulfillments.forEach(async (fulfillment) => {
          fulfillment.items.forEach(async (item) => {
            this.productList.push(item);
          });
        });
      });
    },
    needsBottomDivider(index) {
      return index < this.getLocationOrders.length - 1;
    },
  },
};
</script>
<style scoped>
.re-order {
  padding: 10px;
  margin-top: 50px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
.title {
  padding: 20px;
}
.pagination-wrapper {
  margin-top: 20px;
}
.empty {
  height: 200px;
  width: auto;
}
.search-bar {
  padding: 10px 0px 0px 30px;
}
.filter {
  margin-left: auto;
  padding: 25px 20px 0px 0px;
}
.header {
  display: flex;
}
</style>
