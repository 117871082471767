import { render, staticRenderFns } from "./LocationReStock.vue?vue&type=template&id=bdab2a08&scoped=true&lang=html"
import script from "./LocationReStock.vue?vue&type=script&lang=js"
export * from "./LocationReStock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdab2a08",
  null
  
)

export default component.exports