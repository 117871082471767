<template lang="html">
  <div id="market-place">
    <div class="market-place">
      <div class="results">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MarketplaceMyStockroom',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
 #market-place {
  width: 100%;
 }
.title {
  display: flex;
}
.view-list {
    padding: 22px 0px 0px 15px;
    font-size: 14px;
    color: $orange;
    cursor: pointer;
}
.market-place {
  margin-top: 25px;
}
.search-bar {
  padding: 25px 20px 0px 30px;
}
.results {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}
</style>
