<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "./styles/PageHeader.scss";
</style>
<template>
  <div class="header">
    <div class="menu-bar">
      <a href="/" class="logo">
        <img src="@/assets/layout-v2/simpatra_logo_black.png" class="logo" />
      </a>
      <!-- MENU -->
      <a-menu
        v-model="current"
        class="navigation-tabs"
        mode="horizontal"
      >
        <a-menu-item key="MarketplaceV2">
          <router-link to="/marketplace-v2">Marketplace</router-link>
        </a-menu-item>
        <a-menu-item key="MedCenter">
          <router-link to="/med-center">MedCenter</router-link>
        </a-menu-item>
        <a-menu-item key="Intellipel">
          <router-link to="/intellipel">Intellipel</router-link>
        </a-menu-item>
        <a-menu-item key="PracticeBuilder">
          <router-link to="/practice-builder">Practice Builder</router-link>
        </a-menu-item>
      </a-menu>

      <!-- ACTIONS -->
      <a-menu
        class="navigation-actions"
        mode="horizontal"
      >
        <a-menu-item key="MarketplaceV2">
          <!-- <router-link to="/marketplace-v2">Marketplace</router-link> -->
          <div class="login">
            <a href="https://auth.simpatra.com/" class="btn-login">Login</a>
          </div>
        </a-menu-item>
        <a-menu-item key="MedCenter">
          <div class="signup">
            <a round class="btn-signup" href="https://auth.simpatra.com/signup">
              Signup</a>
          </div>
        </a-menu-item>
      </a-menu>
    </div>
    <div class="mobile-menu-bar">
      <div class="mobile-container">
        <!-- <img
          src="@/assets/layout-v2/mob_menu_button.svg"
          class="menu-icon"
          @click="handeCollapse"
        /> -->
        <input id="toggle" type="checkbox" style="display:none;" />
        <label class="toggle-btn toggle-btn__cross" for="toggle">
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
        </label>
        <nav>
          <ul>
            <li>
              <router-link to="/marketplace-v2">Marketplace</router-link>
            </li>
            <li>
              <router-link to="/med-center">MedCenter</router-link>
            </li>
            <li>
              <router-link to="/intellipel">Intellipel</router-link>
            </li>
            <li>
              <router-link to="/practice-builder">Practice Builder</router-link>
            </li>
          </ul>
        </nav>
        <a href="/">
          <img src="@/assets/layout-v2/simpatra_logo_black.png" />
        </a>
        <img
          src="@/assets/layout-v2/mob_signout_button.svg"
          class="signout-icon"
        />
      </div>
      <!-- <el-menu
        v-if="isCollapse"
        default-active="2"
        class="el-menu-vertical-mob"
      >
        <el-menu-item index="0" class="mobile-menu-close" disabled>
          <i class="el-icon-close" @click="handeCollapse" />
        </el-menu-item>
        <el-menu-item index="mainPage">
          <a href="/" :class="{ 'is-active': isHomePage }">Main Page</a>
        </el-menu-item>
        <el-menu-item index="NewMarketplaceHome">
          <a
            href="/marketplace" :class="{ 'is-active': isMarketPlace }"
          >Marketplace</a>
        </el-menu-item>
        <el-menu-item index="HomeMedCenter">
          <a
            href="/home-med-center"
            :class="{ 'is-active': isMedCenterPageActive }"
          >Med Center</a>
        </el-menu-item>
        <el-menu-item index="HomeIntellipel">
          <a
            href="/home-intellipel"
            :class="{ 'is-active': isIntellipelPageActive }"
          >Intellipel</a>
        </el-menu-item>
        <el-menu-item index="HomePracticeBuilder">
          <a
            href="/home-practice-builder"
            :class="{ 'is-active': isPracticeBuilderPageActive }"
          >Practice builder</a>
        </el-menu-item>
      </el-menu> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePageHeader",
  components: {},
  data() {
    return {
      isCollapse: false,
    };
  },
  computed: {
    current: {
      get() {
        return [this.$route.name];
      },
      set(){}
    },
  },
  methods: {
    handeCollapse() {
      this.isCollapse = !this.isCollapse;
    },
  },
};
</script>
